import React from 'react'
import { Link } from 'gatsby';
import { IconContext } from "react-icons";
import { ImQuotesLeft } from "react-icons/im";
import Boton from '../boton';

const TarjetaCasoExito = (props) => {
  const { cliente, titulo, logo_cliente, slug, quote, persona_quote, cargo } = props
  const urlLogoCliente = `${process.env.API_URL}${logo_cliente}`
  return (
    <article className="slide-exito">
      <div className='slide-exito-container'>

        <div className="slide-exito_col">
          <h3>{cliente}: {titulo}</h3>
          {/*<div className='pildora'>Retail</div>*/}
          <img src={urlLogoCliente} alt=""></img>
          <Link className='ver-exito' to={'/proyectos/' + slug}>Ver proyecto</Link>

        </div>
        <div className="slide-exito_col">
          <div className='exito-quote'>
            <IconContext.Provider value={{ size: "5em", className: "quote-svg" }}>
              <div>
                <ImQuotesLeft />
              </div>
            </IconContext.Provider>

            <p>{quote}</p>
          </div>
          <div className='exito-quote-person'>
            <span>{persona_quote}</span>
            <span><strong>{cargo}</strong></span>
          </div>
        </div>
      </div>
      <div className='exito-ver-todos'>
        <Boton
          color="primario"
          url="/proyectos/"
          texto="Ver todos"
          externo={false}
        />
      </div>
    </article>
  )
}
export default TarjetaCasoExito