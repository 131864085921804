import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import TarjetaCasoExito from "./tarjetaCasoExito"
import "../../styles/casosexitohome.scss"

export const query = graphql`
  query MyQuery {
    allStrapiProyectosecurity(
      limit: 2
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          Cita
          Contacto
          Puesto_del_contacto
          cliente
          slug
          titulo
          Logo_cliente {
            url
          }
        }
      }
    }
  }
`

const SectionCasoExito = () => {
  const data = useStaticQuery(query)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)

      //Tarjetas horizontales
      let scroller = document.querySelector(".slidesContainer")
      let slides = gsap.utils.toArray(".slide-exito")
      console.log(scroller.scrollWidth)

      gsap.to(slides, {
        x: () => -(scroller.scrollWidth - window.innerWidth) + "px",
        ease: "none",
        scrollTrigger: {
          trigger: scroller,
          pin: true,
          scrub: 0.9,
          // snap: 1 / (slides.length - 1),
          end: () => "+=" + scroller.offsetWidth,
        },
      })
    }
  }, [])

  return (
    <section className="" style={{ position: "relative" }}>
      <div className="slidesContainer">
        <h2>Casos de éxito</h2>
        {data.allStrapiProyectosecurity.edges.map(({ node }, key) => (
          <TarjetaCasoExito
            cliente={node.cliente}
            titulo={node.titulo}
            logo_cliente={node.Logo_cliente.url}
            slug={node.slug}
            quote={node.Cita}
            persona_quote={node.Contacto}
            cargo={node.Puesto_del_contacto}
            key={key}
          />
        ))}
      </div>
    </section>
  )
}
export default SectionCasoExito
